import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./textArea.scss";
const TextArea = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  errorText,
  disabled,
  onFocus,
  onBlur,
  isResizable,
  customWrapper,
  maxLength,
  minLength,
  customStyle,
  errorFontSize,
  count,
  withCount,
}) => {
  let wrapperClass = "form-group";

  return (
    <div className={`${wrapperClass} text-area-wrapper ${customWrapper}`}>
      {label && (
        <Text
          Tag="div"
          textType="roman"
          fontSize={"font-16"}
          className="text-area__title"
        >
          {label}
        </Text>
      )}
      <textarea
        type="textArea "
        name={name}
        className={`form-control text-area ${
          errorText && "text-area__error"
        } ${customStyle} ${!isResizable ? "text-area__not-resizable" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled ? "disabled" : ""}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        minLength={minLength}
      />
      <div style={{ display: "flex" }}>
        {errorText && (
          <Text
            className="text-area__error-hint"
            Tag="span"
            textType="roman"
            fontSize={errorFontSize}
          >
            {errorText}
          </Text>
        )}
        {withCount && (
          <Text
            Tag="span"
            textType="roman"
            fontSize={"font-16"}
            className="text-area-count"
          >
            {maxLength - count} حرف متاح
          </Text>
        )}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isResizable: PropTypes.bool,
  customWrapper: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  errorFontSize: PropTypes.string,
  withCount: PropTypes.bool,
};

TextArea.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  isResizable: true,
  maxLength: 10000,
  minLength: 1,
  errorFontSize: "font-14",
  withCount: false,
};

export default TextArea;
