import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { paymentVerify } from "../../../actions/paymentActions";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "./checkoutResult.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import {
  DETAILEDREPORT,
  DETAILED_REPORT_WITH_GRACE_PERIOD,
  ADDITIONAL_ASSESSMENT,
  TRAINING,
  SADAD,
  STATUSES,
  GRACE_PERIOD,
  RE_ASSESSMENT,
  FASTTRACK,
  FAST_TRACK,
} from "../../../common/constants/paymentStatuses";
import Lottie from "react-lottie";
import animationSuccess from "./success_payment.json";
import animationFail from "./failed_payment.json";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { STATUS_REGISTER } from "../../../common/constants/trainingStatuses";
import {
  submitPayment,
  submitPaymentWithCorrection,
} from "../../../actions/establishmentProfileActions";
import toastr from "toastr";
import { createTraining } from "../../../actions/trainingActions";
import PopUp from "../../Components/PopUp/PopUp";
import { getSurvay } from "../../../actions/SAformActions";
import { Button } from "reactstrap";
import { setTrackType } from "../../../components/AssessmentCards/FeatureCard";

const CheckoutResult = (props) => {
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentFail, setIsPaymentFail] = useState(false);
  const [billNumber, setBillNumber] = useState(null);
  const [sadadNumber, setSadadNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [link, setLink] = useState();
  const [surveyLoading, setSurveyLoading] = useState(true); // Loading state
  const state = useSelector((state) => state);
  console.log("props.trackType", props.trackType);

  const handleGetSurvey = async () => {
    try {
      const res = await getSurvay(establishmentProfile.id);
      setLink(res?.url); // Update link after successful API call
    } catch (error) {
      console.error("Failed to fetch survey link:", error);
    } finally {
      setSurveyLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    handleGetSurvey();
  }, [establishmentProfile]);

  useEffect(() => {
    const {
      paymentVerify,
      establishmentProfile,
      location,
      submitPayment,
      submitPaymentWithCorrection,
      paymentDetails,
      createTraining,
      trainingData,
    } = props;
    const { service_type } = match.params;
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get("id");
    let failMessage;

    if (id && service_type !== SADAD) {
      setIsLoading(true);
      paymentVerify(establishmentProfile.id, id)
        .then((val) => {
          setBillNumber(val.result.billNumber);
          setIsPaymentSuccess(true);

          setIsLoading(false);
        })
        .catch((error) => {
          if (
            error === "800.100.155" ||
            error === "800.100.174" ||
            error === "100.400.314" ||
            error === "700.100.300" ||
            error === "000.100.210"
          )
            failMessage = "عذرا العمليه مرفوضه لأن الرصيد غير كافي";
          else failMessage = "عذرا حصل خطأ، الرجاء معاودة الدفع مره آخرى";
          if (service_type === TRAINING) {
            const trainingDetails = {
              address: trainingData.address,
              email: trainingData.email,
              lat: trainingData.lat,
              lng: trainingData.lng,
              mobile: trainingData.mobile,
              status: trainingData.status,
              subject_id: trainingData.subject_id,
            };
            createTraining(
              establishmentProfile.id,
              trainingDetails,
              trainingData
            )
              .then((val) => {
                props.history.push({
                  pathname: `/checkout/${val.training.id}/${TRAINING}/${val.sadad_number}/${val.payment_resp_bill_number}`,
                  state: { failMessage: failMessage },
                });
              })
              .catch((error) => {
                setIsPaymentFail(true);
                setIsLoading(false);
                toastr.error(error);
              });
          } else if (
            service_type === GRACE_PERIOD ||
            service_type === DETAILED_REPORT_WITH_GRACE_PERIOD
          ) {
            const selectedService =
              service_type === GRACE_PERIOD
                ? service_type
                : DETAILED_REPORT_WITH_GRACE_PERIOD;
            submitPaymentWithCorrection(
              paymentDetails.establishmentId,
              paymentDetails.serviceId,
              paymentDetails.periodId,
              paymentDetails.assessmentId,
              paymentDetails.assessmentVersion
            )
              .then((val) => {
                props.history.push({
                  pathname: `/checkout/${val.hyperpay_bill.id}/${selectedService}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`,
                  state: { failMessage: failMessage },
                });
              })
              .catch((error) => {
                setIsPaymentFail(true);
                setIsLoading(false);
                if (error && error.details) {
                  toastr.error(error.details);
                } else {
                  toastr.error("حدث خطأ ما");
                }
              });
          } else if (
            service_type === RE_ASSESSMENT ||
            service_type === FASTTRACK ||
            service_type === ADDITIONAL_ASSESSMENT ||
            service_type === DETAILEDREPORT
          )
            submitPayment(
              paymentDetails.establishmentId,
              paymentDetails.serviceId,
              paymentDetails.periodId,
              paymentDetails.assessmentId,
              paymentDetails.assessmentVersion
            )
              .then((val) => {
                props.history.push({
                  pathname: `/checkout/${val.hyperpay_bill.id}/${service_type}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`,
                  state: { failMessage: failMessage },
                });
              })
              .catch((error) => {
                setIsPaymentFail(true);
                setIsLoading(false);
                if (error && error.details) {
                  toastr.error(error.details);
                } else {
                  toastr.error("حدث خطأ ما");
                }
              });

          setIsPaymentSuccess(false);
        });
    } else if (service_type === SADAD) {
      setBillNumber(location.state.billNumber);
      setSadadNumber(location.state.sadad_number);
      setIsPaymentSuccess(true);
    }
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: isPaymentSuccess ? animationSuccess : animationFail,
    rendererSettings: {
      className: "checkout-lottie-svg",
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { establishmentProfile, match } = props;
  const { service_type } = match.params;
  const serviceType =
    service_type === DETAILED_REPORT_WITH_GRACE_PERIOD
      ? DETAILEDREPORT
      : service_type;

  const isOpen =
    service_type === DETAILED_REPORT_WITH_GRACE_PERIOD ? true : false;
  const [isShowPopUp, setIsShowPopUp] = useState(isOpen);

  const getRedirectionPath = () => {
    let url;
    const { duration, match, trackType } = props;
    const { service_type } = match.params;
    const effectivePeriodId = duration?.period_id || duration?.id;
    const effectiveAssessmentId =
      duration?.assessment_id || duration?.assessment?.id;
    if (!duration) {
      if (trackType === "basic") url = "/establishmentBasicDurationsPage";
      else if (trackType === "special") url = "/establishmentFastTrackPage";
      else url = "/establishmentBasicDurationsPage";
    }
    if (service_type === FASTTRACK) {
      url = "/establishmentFastTrackPage";
    } else if (service_type === SADAD) {
      if (trackType === "basic") url = "/establishmentBasicDurationsPage";
      else if (trackType === "special") url = "/establishmentFastTrackPage";
      else url = "/establishmentBasicDurationsPage";
    } else if (serviceType === RE_ASSESSMENT) {
      if (props.trackType === "basic") url = "/establishmentBasicDurationsPage";
      else if (props.trackType === "special")
        url = "/establishmentFastTrackPage";
    } else if (service_type === ADDITIONAL_ASSESSMENT) {
      url = `/establishmentAssessmentInstruction/${effectivePeriodId}/${duration.assessment_version}/true/${duration.period_assessment_id}`;
    } else if (
      service_type === DETAILED_REPORT_WITH_GRACE_PERIOD ||
      service_type === DETAILEDREPORT
    ) {
      url = `/establishmentAssessmentResult/${effectivePeriodId}/${effectiveAssessmentId}/${establishmentProfile.id}/${duration.assessment_version}`;
    } else if (service_type === TRAINING) {
      url = "/establishmentTrainings/trainings";
      // return `/establishmentTraining/${establishmentProfile.id}/${training_id}/${STATUS_READY}`;
    } else {
      url = "/establishmentBasicDurationsPage";
    }
    props.history.push(url);
  };
  const getFailRedirectionPath = () => {
    const { duration, match } = props;
    const { training_id, training_status } = match.params;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    if (service_type === TRAINING && training_status === STATUS_REGISTER) {
      return `/establishmentTraining/${establishmentProfile.id}/${training_id}/${STATUS_REGISTER}`;
    } else if (service_type === TRAINING && training_status !== STATUS_REGISTER)
      return "/establishmentTrainings/trainings";
    else {
      return `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${establishmentProfile.id}/${duration.assessment_version}/false/${serviceType}/${duration.period_assessment_id}`;
    }
  };
  const getRedirectionText = () => {
    if (service_type === FASTTRACK) {
      return "الانتقال لصفحة المسار المميز";
    } else if (service_type === SADAD) {
      if (props.trackType === "basic") return "الانتقال لصفحة التقييم الذاتي";
      else if (props.trackType === "special")
        return "الانتقال لصفحة المسار المميز";
      else return "الانتقال للصفحة الرئيسية";
    } else if (serviceType === RE_ASSESSMENT) {
      if (props.trackType === "basic") return "الانتقال لصفحة التقييم الذاتي";
      else if (props.trackType === "special")
        return "الانتقال لصفحة المسار المميز";
    } else if (service_type === ADDITIONAL_ASSESSMENT) {
      return "الانتقال لصفحة الخدمة";
    } else if (
      service_type === DETAILED_REPORT_WITH_GRACE_PERIOD ||
      service_type === DETAILEDREPORT
    ) {
      return "الانتقال لصفحة الخدمة";
    } else if (service_type === TRAINING) {
      return "الانتقال لصفحة خدمة التدريب";
    } else {
      return "الانتقال لصفحة التقييم الذاتي";
    }
  };
  const getFailRedirectionText = () => {
    const { training_status } = match.params;

    if (service_type === TRAINING && training_status === STATUS_REGISTER) {
      return "الانتقال لصفحة بيانات التسجيل في الدورة التدريبية";
    } else if (service_type === TRAINING && training_status !== STATUS_REGISTER)
      return "الانتقال لصفحة خدمة التدريب";
    else return "الانتقال لصفحة تفاصيل الخدمة";
  };

  return (
    <PageWrapper withBreadcrumbs breadcrumbLabel={"إتمام عملية الدفع"}>
      <div className="checkout-page">
        {isLoading ? (
          <div className="checkout-payment-loader">
            <div className="loader-wrapper">
              <Loader />
            </div>
          </div>
        ) : (
          <Fragment>
            {isPaymentSuccess ? (
              <div className=" checkout-page-card checkout-page-card__padding checkout-page-payment-card checkout-page-payment-success">
                <div className="lottie-animation-text-wrapper">
                  <div className="lottie-animation">
                    <Lottie options={defaultOptions} />
                  </div>
                  {service_type === SADAD ? (
                    <>
                      <Text
                        Tag="div"
                        textType="h6"
                        className="checkout-page-payment-success"
                      >
                        تم طلب الخدمة بنجاح، يرجى المسارعة بسداد الفاتورة، رقم
                        السداد {sadadNumber}#
                        {/* <br />
                      باستخدام نظام سداد للمدفوعات */}
                      </Text>

                      <div>
                        <button
                          onClick={getRedirectionPath}
                          className="checkout-page-payment-success__button"
                        >
                          <Text Tag="div" textType="h7">
                            {getRedirectionText()}
                          </Text>
                        </button>
                      </div>
                    </>
                  ) : surveyLoading ? (
                    <div className="loader-wrapper">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <Text
                        Tag="div"
                        textType="h6"
                        className="checkout-page-payment-success"
                      >
                        تم دفع رسوم الخدمة بنجاح، رقم الطلب {billNumber}#
                      </Text>

                      <div>
                        <button
                          onClick={getRedirectionPath}
                          className="checkout-page-payment-success__button"
                        >
                          <Text Tag="div" textType="h7">
                            {getRedirectionText()}
                          </Text>
                        </button>
                      </div>

                      {/* Conditionally render survey link based on environment */}
                      {process.env.REACT_APP_ENVIRONMENT === "production" ? (
                        // Production environment: show survey in PopUp
                        <PopUp
                          isOpen={isShowPopUp}
                          isCustomPopUpModel="window-width"
                          exit
                          title=" إستبيان تقييم مستوى الخدمة"
                          onClickCancel={() => {
                            setIsShowPopUp(false);
                          }}
                          closeIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.981"
                              height="13.572"
                              viewBox="0 0 13.981 14.572"
                            >
                              <path
                                id="Shape"
                                d="M12.274,14.266,7,8.764l-5.29,5.5a.971.971,0,0,1-1.414,0,1.072,1.072,0,0,1,0-1.473l5.29-5.5L.3,1.778A1.073,1.073,0,0,1,.3.3a.973.973,0,0,1,1.415,0L7,5.818,12.274.331a.971.971,0,0,1,1.414,0,1.072,1.072,0,0,1,0,1.473L8.412,7.292l5.277,5.5a1.073,1.073,0,0,1,0,1.473.971.971,0,0,1-1.414,0Z"
                                fill="#92929d"
                              />
                            </svg>
                          }
                          boxStyle={{ maxWidth: "850px" }}
                          bodyLink={
                            <iframe
                              src={link}
                              style={{
                                overflow: "auto",
                                width: "100%",
                                maxWidth: "820px",
                                height: "500px",
                              }}
                            />
                          }
                        ></PopUp>
                      ) : (
                        // Staging and development environments: open survey in a new tab
                        isShowPopUp &&
                        link && (
                          <div>
                            <button
                              className="checkout-page-payment-success__button"
                              onClick={() =>
                                window.open(
                                  link,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              <Text Tag="div" textType="h7">
                                إستبيان تقييم مستوى الخدمة
                              </Text>
                            </button>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              isPaymentFail && (
                <div className=" checkout-page-card checkout-page-card__padding checkout-page-payment-card checkout-page-payment-success">
                  <div>
                    <Lottie options={defaultOptions} height={230} width={230} />
                    <Text
                      Tag="div"
                      textType="h6"
                      className="checkout-page-payment-success"
                    >
                      عذرا حصل خطأ، الرجاء معاودة الدفع مره آخرى
                    </Text>

                    <Link
                      to={getFailRedirectionPath()}
                      className="checkout-page-payment-success__link"
                    >
                      <Text Tag="div" textType="h7">
                        {getFailRedirectionText()}
                      </Text>
                    </Link>
                  </div>
                </div>
              )
            )}
          </Fragment>
        )}
      </div>
    </PageWrapper>
  );
};

CheckoutResult.propTypes = {
  paymentVerify: PropTypes.func,
  submitPayment: PropTypes.func,
  submitPaymentWithCorrection: PropTypes.func,
  establishmentProfile: PropTypes.object,
  duration: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  services: PropTypes.array,
  createTraining: PropTypes.func,
  trackType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishment.establishmentProfile,
  duration: state.duration.selectedDuration,
  services: state.payment.reopen_assessment_services,
  paymentDetails: state.paymentInitiate,
  trainingData: state.trainings.trainingData,
  trackType: state.trackType,
});
const mapDispatchToProps = (dispatch) => ({
  paymentVerify: (establishmentId, checkoutId) =>
    dispatch(paymentVerify(establishmentId, checkoutId)),
  submitPayment: (
    establishment_id,
    payment_service_id,
    period_id,
    assessment_id,
    assessmentVersion
  ) =>
    dispatch(
      submitPayment(
        establishment_id,
        payment_service_id,
        period_id,
        assessment_id,
        assessmentVersion
      )
    ),
  submitPaymentWithCorrection: (
    establishment_id,
    payment_service_ids,
    period_id,
    assessment_id,
    assessmentVersion
  ) =>
    dispatch(
      submitPaymentWithCorrection(
        establishment_id,
        payment_service_ids,
        period_id,
        assessment_id,
        assessmentVersion
      )
    ),
  createTraining: (establishment_id, training, trainingData) =>
    dispatch(createTraining(establishment_id, training, trainingData)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutResult));
