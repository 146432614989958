import React, { Component } from "react";

import Select from "react-select";

import PropTypes from "prop-types";
import "./labeledDropDown.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const selectStyles = {
  control: (base, { isDisabled, isFocused }) => {
    return {
      ...base,
      background: "#ffffff",
      borderRadius: 5,
      borderColor: isFocused ? "#15415a" : "#cccdcd",
      boxShadow: isFocused ? null : null,
      color: "#44444f",
      width: "100%",
      height: "45px",
      "&:hover": {
        borderColor: isFocused ? "#15415a" : "#979797",
        boxShadow: isFocused ? null : null,
      },
      ...(isDisabled && {
        borderColor: "#e0e0e0",
        background: " #f0f0f0",
        color: "#dedede",
        pointerEvents: "auto",
        cursor: "not-allowed",
        "&:hover": {
          borderColor: "#e0e0e0",
        },
      }),
    };
  },
  placeholder: (defaultStyles, { isDisabled }) => {
    return {
      ...defaultStyles,
      color: "#cccdcd",
      ...(isDisabled && {
        color: "#c4c4c4",
      }),
    };
  },
  menu: (base) => ({
    ...base,
    borderRadius: 5,
    marginTop: 0,
    width: "100%",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  option: (styles, { isFocused }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    return {
      ...styles,
      backgroundColor: isFocused ? "#0000000A" : null,
      paddingTop: ITEM_PADDING_TOP,
      color: "#44444f",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      height: 65,
      display: "flex",
      alignItems: "center",
    };
  },
};
export default class LabeledDropDown extends Component {
  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
      label,
      options,
      placeholder,
      onChange,
      value,
      defaultValue,
      name,
      onFocus,
      onBlur,
      errorText,
      dropDownWrapperStyle,
    } = this.props;
    return (
      <div className="labeled-dropdown ">
        {label && (
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-16"}
            className="labeled-dropdown__title"
          >
            {label}
          </Text>
        )}
        <div className={`drop-down-wrapper ${dropDownWrapperStyle}`}>
          <Select
            className="basic-single labeled-dropdown-select"
            classNamePrefix="select"
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name={name}
            options={options}
            placeholder={placeholder}
            styles={{
              ...selectStyles,
              control: (base, { isDisabled, isFocused }) => {
                return {
                  ...base,
                  background: "#ffffff",
                  borderRadius: 5,
                  borderColor: isFocused
                    ? "#15415a"
                    : errorText
                    ? "red"
                    : "#cccdcd",
                  boxShadow: isFocused ? null : null,
                  color: "#44444f",
                  width: "100%",
                  height: "45px",
                  "&:hover": {
                    borderColor: isFocused
                      ? "#15415a"
                      : errorText
                      ? "red"
                      : "#979797",
                    boxShadow: isFocused ? null : null,
                  },
                  ...(isDisabled && {
                    borderColor: "#e0e0e0",
                    background: " #f0f0f0",
                    color: "#dedede",
                    pointerEvents: "auto",
                    cursor: "not-allowed",
                    "&:hover": {
                      borderColor: "#e0e0e0",
                    },
                  }),
                };
              },
            }}
            onChange={onChange}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {errorText && (
            <Text
              className="labeled-dropdown__error-hint"
              Tag="span"
              textType="roman"
              fontSize={"font-14"}
            >
              {errorText}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
LabeledDropDown.defaultProps = {
  isClearable: true,
  isDisabled: false,
  isLoading: false,
  isRtl: true,
  isSearchable: false,
  defaultValue: null,
  name: "color",
  options: [
    {
      label: "option 1",
      value: 1,
    },
    {
      label: "option 2",
      value: 2,
    },
  ],
  placeholder: "اختر",
  onFocus: () => {},
  onBlur: () => {},
};

LabeledDropDown.propTypes = {
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errorText: PropTypes.string,
};
