import React, { useEffect, useState } from "react";
import "./violators-row.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import TitledTextInput from "../../../../../../components/TitledTextInput/TitledTextInput";
import { digitsOnly } from "../../../../../../../utilities/helpers";
import { useDispatch } from "react-redux";
import { ASSESSMENT_FORM_VIOLATORS } from "../../../../../../actions/actionTypes";

export const ViolatorsRow = ({ step, data, isError, setErrors, errors }) => {
  const dispatch = useDispatch();
  const [violators, setViolators] = useState(
    data?.violation_number?.toString()
  );

  const saveViolatorsNumber = (violators, didUpdate = false) => {
    return {
      type: ASSESSMENT_FORM_VIOLATORS,
      violators,
      didUpdate,
    };
  };
  useEffect(() => {
    dispatch(saveViolatorsNumber(data?.violation_number?.toString()));
  }, []);
  return (
    <div>
      <Text
        Tag={"div"}
        textType="bold"
        fontSize={"font-18"}
        mobileFontSize={"font-16-mobile"}
        className={"violators-row-title"}
      >
        {"عدد المخالفين"}
      </Text>
      <TitledTextInput
        type="text"
        onChange={(event) => {
          setErrors({ ...errors, violators: null });
          const digits = digitsOnly(event.target.value);
          setViolators(digits);
          dispatch(saveViolatorsNumber(digits, true));
        }}
        placeholder="يرجى كتابة عدد المخالفين"
        value={violators}
        name="violatorsNum"
        errorText={isError ? "* الحقل مطلوب" : ""}
        maxLength="10"
        disabled={false}
        id="input-register-email"
      />
    </div>
  );
};
