import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./non-compliance-row.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import TextArea from "../../../../../../components/common/TextArea";
import { lettersWithNumbersAndSpecialCharacters } from "../../../../../../../utilities/helpers";
import { ASSESSMENT_FORM_NON_COMPLIANCE_TEXT } from "../../../../../../actions/actionTypes";

export const NonComplianceRow = ({
  step,
  data,
  isError,
  setErrors,
  errors,
}) => {
  const [count, setCount] = useState(data?.note ? data?.note.length : 0);
  const dispatch = useDispatch();
  const [nonCompliance, setNonCompliance] = useState(data?.note);

  const saveNonComplianceText = (nonComplianceText, didUpdate = false) => {
    return {
      type: ASSESSMENT_FORM_NON_COMPLIANCE_TEXT,
      nonComplianceText,
      didUpdate,
    };
  };

  useEffect(() => {
    dispatch(saveNonComplianceText(data?.note));
  }, []);
  const isNonComplianceTextBetween1And10 =
    isError &&
    nonCompliance?.trim().length < 10 &&
    nonCompliance?.trim().length > 0;
  return (
    <div>
      <Text
        Tag={"div"}
        textType="bold"
        fontSize={"font-18"}
        mobileFontSize={"font-16-mobile"}
        className={"non-compliance-text-area-title "}
      >
        {"التبرير"}
      </Text>
      <TextArea
        name="description"
        isResizable={false}
        placeholder="يرجى كتابة التبرير"
        onChange={(item) => {
          setErrors({ ...errors, nonCompliance: null });

          const descriptionInput = lettersWithNumbersAndSpecialCharacters(
            item.target.value
          );
          setCount(descriptionInput.length);
          setNonCompliance(descriptionInput);
          dispatch(saveNonComplianceText(descriptionInput, true));
        }}
        value={nonCompliance}
        errorText={
          isNonComplianceTextBetween1And10
            ? "* يجب عليك ادخال 10 حرف على الاقل"
            : isError
            ? "* الحقل مطلوب"
            : ""
        }
        maxLength={300}
        minLength={10}
        customStyle={"non-compliance-text-area-custom-style"}
        customWrapper={"non-compliance-text-area-custom-wrapper"}
        errorFontSize={"font-16"}
        count={count}
        withCount
      />
    </div>
  );
};
