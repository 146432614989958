import { combineReducers } from "redux";
import assessment from "./assessment/assessmentReducer";
import ajaxStatus from "./ajaxStatusReducer";
import caliberCategories from "./caliberCategoryReducer";
import calibers from "./caliberReducer";
import establishment from "./establishmentReducer";
import establishmentSelfAssessmentResult from "./establishmentSelfAssesmentResultReducer";
import report from "./reportReducer";
import violationCategories from "./violationCategoryReducer";
import attachmentCategories from "./attachmentCategoryReducer";
import questionCategories from "./questionCategoryReducer";
import caliberSpecializations from "./caliberSpecializationReducer";
import periodSelfAssessment from "./periodSelfAssessmentReducer";
import caliberSubjects from "./caliberSubjectReducer";
import auth from "./authReducer";
import SAform from "./SAformReducer";
import notifications from "./notificationsReducer";
import SAreport from "./SAreportReducer";
import payment from "./paymentReducer";
import trainings from "./trainingReducer";
import trainingSubjects from "./trainingSubjectReducer";
import unified from "./unifiedReducer";
import certificate from "./certificateReducer";
import history from "./historyReducer";
import billInfo from "./billInfoReducer";
import FastTrackForBranches from "./FastTrackForBranchesReducer";
import paymentInitiate from "./paymentInitiateReducer";
import imsInfo from "./imsInfoReducer";
import duration from "./durationReducer";
import trackType from "./trackTypeReducer";

// ** Accessibility Reducer
import Accessibility from "./AccessibilityReducer";
import assessmentForm from "./assessmentFormReducer";

const rootReducer = combineReducers({
  assessment,
  ajaxStatus,
  establishment,
  establishmentSelfAssessmentResult,
  caliberCategories,
  violationCategories,
  calibers,
  attachmentCategories,
  caliberSpecializations,
  periodSelfAssessment,
  questionCategories,
  caliberSubjects,
  report,
  auth,
  notifications,
  SAform,
  SAreport,
  payment,
  trainings,
  trainingSubjects,
  unified,
  certificate,
  history,
  billInfo,
  FastTrackForBranches,
  paymentInitiate,
  imsInfo,
  duration,
  accessibility: Accessibility,
  trackType,
  assessmentForm,
});

export default rootReducer;
