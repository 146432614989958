import React from "react";
import PropTypes from "prop-types";
import "./violation-details.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";

export const ViolationDetails = ({ violation_number, note }) => {
  return (
    <div className="violation-details">
      {violation_number && (
        <div className="violation-details__item">
          <Text
            Tag={"div"}
            textType="bold"
            fontSize="font-18"
            mobileFontSize="font-16-mobile"
            className="violation-details__title"
          >
            عدد المخالفين:
          </Text>

          <Text
            Tag={"div"}
            textType="roman"
            fontSize="font-18"
            mobileFontSize="font-16-mobile"
            className="violation-details__desc"
          >
             {violation_number}
          </Text>
        </div>
      )}

      {note && (
        <div className="violation-details__item">
          <Text
            Tag={"div"}
            textType="bold"
            fontSize="font-18"
            mobileFontSize="font-16-mobile"
            className="violation-details__title"
          >
            التبرير:
          </Text>
          <Text
            Tag={"div"}
            textType="roman"
            fontSize="font-18"
            mobileFontSize="font-16-mobile"
            className="violation-details__desc"
          >
            {note}
          </Text>
        </div>
      )}
    </div>
  );
};

ViolationDetails.propTypes = {
  violation_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  note: PropTypes.string,
};