import jsPDF from "jspdf";
import {
  FrutigerLTArabic45Light,
  FrutigerLTArabic55Roman,
  FrutigerLTArabic65Bold,
  Frutigerltarabic75black,
} from "./fonts";
import {
  CertificateBackground,
  MHRSDLogo,
  ImsLogo,
  stampScore,
  grayScore,
  goldScore,
} from "./images";

export function PdfCertificate(
  establishmentName = "",
  score = 100,
  certificateNumber = "",
  certificateEndDate = new Date()
) {
  const pdf = new jsPDF("l", "pt", "a4", true);
  pdf.setR2L(true);

  let width = pdf.internal.pageSize.getWidth();
  let height = pdf.internal.pageSize.getHeight();

  pdf.addFileToVFS("FrutigerLTArabic65Bold.ttf", FrutigerLTArabic65Bold);
  pdf.addFileToVFS("FrutigerLTArabic55Roman.ttf", FrutigerLTArabic55Roman);
  pdf.addFileToVFS("FrutigerLTArabic45Light.ttf", FrutigerLTArabic45Light);
  pdf.addFileToVFS("Frutigerltarabic75black.ttf", Frutigerltarabic75black);

  pdf.addFont("FrutigerLTArabic65Bold.ttf", "FrutigerLTArabic65Bold", "bold");
  pdf.addFont(
    "FrutigerLTArabic55Roman.ttf",
    "FrutigerLTArabic55Roman",
    "roman"
  );
  pdf.addFont(
    "FrutigerLTArabic45Light.ttf",
    "FrutigerLTArabic45Light",
    "light"
  );
  pdf.addFont(
    "Frutigerltarabic75black.ttf",
    "Frutigerltarabic75black",
    "black"
  );

  pdf.setFont("FrutigerLTArabic65Bold");

  const processArabicText = (arabicText, x, y, alignment = "right") => {
    const options = {
      isInputVisual: false,
      isOutputVisual: true,
      isInputRtl: true,
      isOutputRtl: true,
      align: alignment,
    };
    return pdf.text(arabicText, x, y, options);
  };

  pdf.addImage(
    CertificateBackground,
    "PNG",
    0,
    0,
    width,
    height,
    "CertificateBackground"
  );

  // pdf.setFillColor("#ffffff", "100");
  // pdf.roundedRect(15, 15, width - 40, height - 40, 0, 0);
  pdf.addImage(MHRSDLogo, "PNG", width - 180, 50, 130, 40, "MHRSDLogo", "FAST");
  pdf.addImage(ImsLogo, "PNG", 50, 50, 80, 50, "ImsLogo", "FAST");

  pdf.setFont("FrutigerLTArabic65Bold", "bold");
  pdf.setFontSize(30);
  pdf.setTextColor("#083349");
  const title = "شهادة الإمتثال";

  const xTitle = width / 2;
  processArabicText(title, xTitle, 160, "center");
  pdf.setFontSize(18);
  pdf.setFont("FrutigerLTArabic55Roman", "roman");

  const text1 = "تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذه الشهادة لـ";
  const xText1 = width / 2;
  processArabicText(text1, xText1, 250, "center");

  pdf.setFont("FrutigerLTArabic65Bold", "bold");
  const text2 = establishmentName;
  const xText2 = width / 2;
  processArabicText(text2, xText2, 290, "center");

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  const text3 = `وذلك لامتثالها لقوانين وأنظمة الوزارة بنسبة %${score}`;
  const xText3 = width / 2;
  processArabicText(text3, xText3, 330, "center");

  const scoreLogo =
    score === 100 ? goldScore : score >= 80 ? grayScore : stampScore;
  pdf.addImage(
    scoreLogo,
    "PNG",
    width / 2 - 50,
    380,
    80,
    80,
    scoreLogo,
    "FAST"
  );

  const qrImage = document.getElementById("qrcode");
  const dataURL = qrImage.toDataURL();
  pdf.addImage(dataURL, "PNG", 60, height - 110, 50, 50, "qrcode", "FAST");

  pdf.setFontSize(8);

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  const qrText = "التحقق من صحة الشهادة";
  processArabicText(qrText, 85, height - 50, "center");

  if (score >= 80){
  const certNumber = `رقم الشهادة ${certificateNumber}`;
  const xCertNumber = width - 60;
  processArabicText(certNumber, xCertNumber, height - 60);

  const certExpire = `تاريخ انتهاء الشهادة ${certificateEndDate}`;
  const xCertExpire = width - 60;

  processArabicText(certExpire, xCertExpire, height - 50);
  }
  pdf.save("شهادة الإمتثال.pdf");
}
