import initialState from "./initialState";
import {
  ASSESSMENT_FORM_NON_COMPLIANCE_TEXT,
  ASSESSMENT_FORM_VIOLATORS,
} from "../actions/actionTypes";

export default function assessmentForm(
  state = initialState.assessmentForm,
  action
) {
  switch (action.type) {
    case ASSESSMENT_FORM_NON_COMPLIANCE_TEXT:
      return Object.assign({}, state, {
        nonComplianceText: action.nonComplianceText,
        didUpdate: action.didUpdate,
      });
    case ASSESSMENT_FORM_VIOLATORS:
      return Object.assign({}, state, {
        violators: action.violators,
        didUpdate: action.didUpdate,
      });

    default:
      return state;
  }
}
